import { useEffect, useState } from 'react';

import { Meta } from '@app/api/pagination';
import {
  getCollectionFilms,
  getCollectionFilmsExpired,
} from '@app/api/resources/Collections';
import { Film } from '@app/api/resources/Film';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetCollectionFilms = ({
  collectionSlug,
  perPage = 24,
  fetchExpired = false,
}: {
  collectionSlug: string;
  perPage?: number;
  fetchExpired?: boolean;
}): [Film[], Meta, boolean, boolean, () => void] => {
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [pageMeta, setPageMeta] = useState(null);

  const httpContext = useAppSelector(state => state.appState.httpContext);

  const doFetchNextPage = () => {
    const { current_page, total_pages } = pageMeta;
    if (current_page < total_pages) {
      setPageNum(prev => prev + 1);
    }
  };

  const doGetFilmCollectionItems = async () => {
    try {
      setIsLoading(true);
      const {
        data: { films, meta },
      } = fetchExpired
        ? await getCollectionFilmsExpired({
            httpContext,
            collectionSlug,
            pageNum,
            perPage,
          })
        : await getCollectionFilms({
            httpContext,
            collectionSlug,
            pageNum,
            perPage,
          });
      setPageData(prev => [...prev, ...films]);
      setPageMeta(meta);
    } catch (fetchError) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPageMeta(null);
    setPageNum(1);
    setPageData([]);
  }, [collectionSlug]);

  useEffect(() => {
    if (typeof collectionSlug === 'string') {
      doGetFilmCollectionItems();
    }
  }, [collectionSlug, pageNum, perPage]);

  return [pageData, pageMeta, isLoading, isError, doFetchNextPage];
};

export default useGetCollectionFilms;
