import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';

import { ONE_YEAR_IN_DAYS, THIRTY_DAYS } from '@app/services/date-utils';
import { gtmEvent } from '@app/services/gtm';

import { color as themeColor } from '@app/themes/mubi-theme';

import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import FadeIn from '@app/components/FadeIn';
import { CloseIcon } from '@app/components/icons/CommonIcons';
import { MubiLogoIcon } from '@app/components/icons/LogoIcons';

const CLOSE_COUNT_COOKIE_NAME = 'notebook_pitch_popup_close_count';
const HIDE_COOKIE_NAME = 'notebook_pitch_popup_hide';
const CLOSE_COUNT_THRESHOLD = 10;

const PitchPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const onboardingTrigger = useOnboardingTrigger();
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  useEffect(() => {
    // now that it's in the DOM we can fade it in
    setTimeout(() => setShowPopup(true));
  }, []);

  const handleClose = () => {
    trackSnowplowClickEvent({
      data: {
        clickType: 'pitch_pop_up_dismiss',
        element: 'pitch_pop_up',
      },
    });
    setShowPopup(false);
    const closeCount =
      (parseInt(Cookies.get(CLOSE_COUNT_COOKIE_NAME), 10) || 0) + 1;
    if (closeCount >= CLOSE_COUNT_THRESHOLD) {
      Cookies.set(CLOSE_COUNT_COOKIE_NAME, '0', {
        expires: ONE_YEAR_IN_DAYS,
      });
      Cookies.set(HIDE_COOKIE_NAME, 'true', {
        expires: THIRTY_DAYS,
      });
    } else {
      Cookies.set(CLOSE_COUNT_COOKIE_NAME, closeCount.toString(), {
        expires: ONE_YEAR_IN_DAYS,
      });
    }
  };

  const handleClickCta = () => {
    trackSnowplowClickEvent({
      data: {
        clickType: 'pitch_pop_up_accept_offer',
        element: 'pitch_pop_up',
      },
    });
    onboardingTrigger('promoNew', {
      specialPromo: { code: 'notebook' },
      contextData: {
        redirectUrl: '/showing',
      },
    });
    gtmEvent({
      event: 'onboarding_page',
      page: 'notebook_pitch_popup_click',
    });
  };

  return (
    <FadeIn isShowing={showPopup}>
      <Container>
        <Content>
          <Logo>
            <MubiLogoIcon
              logoColor={themeColor.mubiBlue}
              dotsColor={themeColor.mubiBlue}
              width="100%"
            />
          </Logo>
          <PitchLink onClick={handleClickCta}>
            <AboutCopy>
              Stream beautiful cinema.
              <br />
              A new hand-picked film,
              <br />
              every day.
            </AboutCopy>
            <AboutCTA>1 month for free</AboutCTA>
          </PitchLink>
          <ButtonContainer>
            <UnstyledButton onClick={handleClose}>
              <ButtonSize>
                <CloseIcon width="100%" color={themeColor.darkText} />
              </ButtonSize>
            </UnstyledButton>
          </ButtonContainer>
        </Content>
      </Container>
    </FadeIn>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  margin: 0 auto;
`;

const Content = styled.div`
  max-width: 370px;
  height: 109px;

  background-color: white;
  box-shadow: -10px 10px 17px 0 rgba(0, 0, 0, 0.2);

  padding: 20px 14px 16px;
`;

const Logo = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 20px;
  margin-top: -5px;
  width: 50px;

  @media (min-width: ${props => props.theme.mq.mobile}) {
    width: 60px;
  }

  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 66px;
  }
`;

const PitchLink = styled.a`
  display: inline-block;
  vertical-align: top;
  font-weight: bold;
  font-size: 11px;
  margin: 0 12px;
  line-height: 16px;
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.mq.mobile}) {
    font-size: 12px;
    margin: 0 15px;
  }

  @media (min-width: ${props => props.theme.mq.tablet}) {
    font-size: 14px;
    margin: 0 17px;
  }
`;

const AboutCopy = styled.p`
  color: ${props => props.theme.color.lightText};
`;

const AboutCTA = styled.p`
  display: block;
  margin-top: 9px;
  color: ${props => props.theme.color.mubiBlue};
`;

const ButtonContainer = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const ButtonSize = styled.div`
  width: 12px;
  height: 12px;

  @media (min-width: ${props => props.theme.mq.mobile}) {
    width: 14px;
    height: 14px;
  }
`;

export default PitchPopup;
