import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import Cookies from 'js-cookie';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { isNowInSpecificTimezoneBeforeDate } from '@app/services/date-utils';
import { sortArrayOfObjectsByProperty } from '@app/services/utils';

import useGetCollectionFilms from '@app/hooks/fetch-data/useGetCollectionFilms';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import FilmStickyPopup from '@app/components/notebook/FilmStickyPopup';
import PitchPopup from '@app/components/notebook/PitchPopup';

const HIDE_FILM_STICKY_COOKIE_NAME = 'closed_film_sticky_today';
const HIDE_PITCH_POPUP_COOKIE_NAME = 'notebook_pitch_popup_hide';
const HALF_A_DAY = 0.5;

const getRelatedFilmIfOneExists = (
  films: Film[],
  filmsRelatedToPost: Film[],
): Film => {
  if (films.length > 0 && films.length > 0) {
    const filmIdsRelatedToPost = filmsRelatedToPost.map(({ id }) => id);
    const relatedFilm = films.find(film =>
      filmIdsRelatedToPost.includes(film.id),
    );
    return relatedFilm;
  }
  return null;
};

const shouldShowPitchPopup = (relatedFilm: Film) => {
  if (Cookies.get(HIDE_PITCH_POPUP_COOKIE_NAME) !== 'true' && !relatedFilm) {
    Cookies.set(HIDE_PITCH_POPUP_COOKIE_NAME, 'true', {
      expires: HALF_A_DAY,
    });
    return true;
  }
  return false;
};

const shouldShowFilmSticky = () => {
  if (!Cookies.get(HIDE_FILM_STICKY_COOKIE_NAME)) {
    return true;
  }
  return false;
};

const NotebookPromoContainer = ({
  filmsRelatedToPost = null,
}: {
  filmsRelatedToPost?: Film[];
}) => {
  const [relatedFilm, setRelatedFilm] = useState<Film>(null);
  const [promoToDisplay, setPromoToDisplay] = useState<boolean | string>(false);

  const { activeSubscriber, promo, trialsEnabled, countryFullTimeZone } =
    useAppSelector(
      state => ({
        activeSubscriber: state.user.activeSubscriber,
        promo: state.appState?.onboardingConfig?.promo,
        trialsEnabled: state.appState.trialsEnabled,
        countryFullTimeZone: state.user.countryFullTimeZone,
      }),
      shallowEqual,
    );

  const [newOnMubiFilms] = useGetCollectionFilms({
    collectionSlug: 'new-on-mubi',
  });

  const mostPopularFilm = sortArrayOfObjectsByProperty(
    newOnMubiFilms,
    'popularity',
  )?.[0];

  const initPromo = () => {
    if (!activeSubscriber) {
      if (shouldShowPitchPopup(relatedFilm)) {
        setPromoToDisplay('pitch_popup');
      } else if (shouldShowFilmSticky()) {
        if (relatedFilm || mostPopularFilm) {
          setPromoToDisplay('film_sticky');
        }
      }
    }
  };

  useEffect(() => {
    if (filmsRelatedToPost && !promoToDisplay) {
      setRelatedFilm(
        getRelatedFilmIfOneExists(newOnMubiFilms, filmsRelatedToPost),
      );
      setTimeout(() => initPromo(), 2000);
    }
  }, [filmsRelatedToPost]);

  // Hide for Priscilla banner duration (TODO delete after this date)
  if (
    isNowInSpecificTimezoneBeforeDate(
      '2024-02-29 23:59:59',
      countryFullTimeZone,
    )
  ) {
    return null;
  }

  if (!trialsEnabled || !newOnMubiFilms || newOnMubiFilms.length === 0) {
    return null;
  }

  if (promoToDisplay === 'pitch_popup' && !promo) {
    return <PitchPopup />;
  }

  if (promoToDisplay === 'film_sticky') {
    const film = mostPopularFilm || relatedFilm;
    if (film) {
      return (
        <StickySpace>
          <FilmStickyPopup film={film} />
        </StickySpace>
      );
    }
  }
  return null;
};

const StickySpace = styled.div`
  width: 100%;
  height: 54px;
  @media (min-width: ${props => props.theme.mq.tablet}) {
    min-height: 60px;
  }
`;

export default NotebookPromoContainer;
