import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { isLeavingInHowManyDays } from '@app/api/resources/Consumables';
import { Film, getDirectorsStringForFilm } from '@app/api/resources/Film';

import useGetFilmStillUrl from '@app/hooks/helpers/useGetFilmStillUrl';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import FadeIn from '@app/components/FadeIn';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';

const FilmStickyPopup = ({ film }: { film: Film }) => {
  const [showPopup, setShowPopup] = useState(false);

  const filmStillImageUrl = useGetFilmStillUrl(film);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const getNumDaysTillExpiryCopy = () => {
    if (film.consumable) {
      const numDaysTillExpiry = isLeavingInHowManyDays(film.consumable);

      if (typeof numDaysTillExpiry === 'number' && numDaysTillExpiry > 14) {
        return null;
      }

      if (typeof numDaysTillExpiry === 'number' && numDaysTillExpiry > 1) {
        return `${numDaysTillExpiry} days left`;
      }
      if (numDaysTillExpiry === 1) {
        return '1 day left';
      }
      return 'Expiring at midnight';
    }

    return null;
  };

  const daysTillExpiry = getNumDaysTillExpiryCopy();

  return (
    <FadeIn isShowing={showPopup}>
      <Container>
        <PageSection minHeight="100%" useLegacyMQ>
          <Content
            onClick={() =>
              trackSnowplowClickEvent({
                data: {
                  clickType: 'film_play',
                  element: 'film_sticky_pop_up',
                },
                film,
              })
            }
          >
            <FilmStillLink
              href={`/films/${film.slug}`}
              imgUrl={filmStillImageUrl}
            />

            <Copy>
              <CopyLeft>
                <FilmTitleLink href={`/films/${film.slug}`}>
                  {film.title}
                </FilmTitleLink>
                <DirectedBy> Directed by </DirectedBy>
                <DirectorsList>{getDirectorsStringForFilm(film)}</DirectorsList>
                <MobileCtaLink href={`/films/${film.slug}`}>
                  Watch now
                </MobileCtaLink>
              </CopyLeft>
              <CopyRight>
                <WatchNowButtonLink url={`/films/${film.slug}`}>
                  Watch now
                </WatchNowButtonLink>
                {daysTillExpiry && (
                  <DaysTillExpiry>{daysTillExpiry}</DaysTillExpiry>
                )}
              </CopyRight>
            </Copy>
          </Content>
        </PageSection>
      </Container>
    </FadeIn>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 54px;
  background-color: ${props => props.theme.color.white};
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 -1px 9px rgba(0, 0, 0, 0.16);

  @media (min-width: ${props => props.theme.mq.tablet}) {
    min-height: 60px;
    font-size: 18px;
    line-height: 22px;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    min-height: 60px;
  }
`;

const FilmStillLink = styled(Link)<{ imgUrl: string }>`
  background-image: url(${props => `${props.imgUrl}?size=160x`});
  background-color: #2d2d2d;
  width: 72px;
  height: 40px;
  background-size: cover;
  background-position: center center;
  margin-right: 12px;
`;

const Copy = styled.div`
  padding: 8px 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.color.lightText};
  align-items: center;
`;

const CopyLeft = styled.div``;

const FilmTitleLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.color.lightText};

  &:visited {
    color: ${props => props.theme.color.lightText};
  }
  &:hover {
    color: ${props => props.theme.color.lightText};
    text-decoration: none;
  }
`;

const DirectedBy = styled.span``;
const DirectorsList = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`;

const CopyRight = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.mq.tablet}) {
    display: block;

    white-space: nowrap;
    margin-left: 40px;
    display: flex;
    align-items: center;
  }
`;

const MobileCtaLink = styled(Link)`
  color: ${props => props.theme.color.mubiBlue};
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: ${props => props.theme.mq.tablet}) {
    display: none;
  }
`;

const DaysTillExpiry = styled.div`
  margin-left: 14px;
`;

const WatchNowButtonLink = styled(MubiButtonLink)`
  padding: 6px 14px;
`;

export default FilmStickyPopup;
