import { Film } from '@app/api/resources/Film';

import { getFilmStillUrlForExperiment } from '@app/api/services/experiments';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetFilmStillUrl = (film: Film) => {
  const experiments = useAppSelector(state => state?.experiments?.experiments);

  const filmStillImageUrl = getFilmStillUrlForExperiment(experiments, film);

  return filmStillImageUrl;
};

export default useGetFilmStillUrl;
